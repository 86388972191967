.login-row{
	padding-top: 60px;
}

.btn-logout {
    color: #fff;
	font-size: 1.2rem;
	
}

nav ul a {
	font-size: 1.4rem;
}

.header {
    color: #03a9f4;
    font-weight: 400;

}

.avatar-list {
    max-height:300px; 
    width:auto; 
}

.card-content-list-person{
    min-height: 200px;
    max-height: 200px;
}

.image-home{
    padding-top: 40px;
    min-width: 500px;
    max-width: 800px;
    width: auto;
    height: auto;
}