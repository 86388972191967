body {
  background-color: #fafafa;
  display: block;
  margin: 0;
}

div {
  display: block;
}

.w100{
  width: 100%;

}